import React from "react";

function Project() {
 return (
  <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
    <div className="relative w-full h-full flex justify-center items-center">
      <iframe
        title="Projects"
        src="https://www.youtube.com/embed/OIXhaZoq5iQ?si=YBFyRoLwqFUMFZLL&amp;controls=1"
        className="w-full h-full"
        allow="autoplay"
      ></iframe>
    </div>
  </div>
);
}

export default Project;
